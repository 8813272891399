@import "styles/variables";

.content {
  background-color: $header-color;
}

.icon {
  margin-right: 8px;
}

.profileButton {
  margin-left: auto;
}