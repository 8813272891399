@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.summaryTable {
  display: grid;
  max-width: 100%;
  width: 100%;

  button {
    border: none;
  }
}

.summaryTableHeaderCell,
.summaryTableCell {
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding: 4px 10px;
  min-height: 45px;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
}

.summaryTable.leftAlign .summaryTableHeaderCell:first-of-type,
.summaryTable.leftAlign .summaryTableCell:first-of-type {
  align-items: start;
}

.summaryTableHeaderCell {
  border-bottom: 2px solid black;
  justify-content: center;
}

.summaryTableRow {
  display: contents;
}

.summaryTableRow:nth-child(2n + 1) .summaryTableCell {
  background-color: $table-striped-bg;
}

.summaryTableRow:hover .summaryTableCell {
  background-color: rgba($black, $table-striped-bg-factor * 2);
}

.summaryTableRow:not(:last-of-type) .summaryTableCell {
  border-bottom: 1px solid lightgrey;
}

.sourceLabelCell {
  display: flex;
  flex-direction: row;
}
