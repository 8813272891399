@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "styles/variables";

.hero {
  background-color: $header-color;
  color: white;
  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: $spacer $spacer $spacer;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 5 * $spacer 3 * $spacer;
  }
}

.content {
  margin: 0 auto;
  @media (max-width: map-get($grid-breakpoints, xxl)) {
    max-width: 1200px;
  }
  @media (max-width: map-get($grid-breakpoints, lg)) {
    padding: $spacer $spacer $spacer;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    padding: 2 * $spacer 3 * $spacer 3 * $spacer;
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacer;
}

.offerNotFoundContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-size: 10rem;
  }
}

.offerSummaryContainer {
  display: grid;
  align-items: start;
  padding-top: 2 * $spacer;
}

.offerSummary {
  text-align: center;
}

.tableContainer {
  position: relative;
  border-radius: 8px;
  border: 1px solid lightgray;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $spacer;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    grid-row: 3;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    grid-row: 1;
  }

  .noPackages {
    padding: 20px;
    margin: 0;
    text-align: center;

    .noPackagesEmoji {
      font-size: 5rem;
    }
  }
}

.promoBanner {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    grid-row: 4;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    grid-row: 2;
  }
}

.timeframeSelect {
  display: inline-block;
  margin-left: $spacer;
}

.tableOptions {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    grid-row: 2;
  }
  @media (min-width: map-get($grid-breakpoints, lg)) {
    grid-row: 1 / 3;
  }
}

.tableLoadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.8) 0%,
    rgba(255, 255, 255, 0.97) 45%,
    rgba(255, 255, 255, 0.97) 55%,
    rgba(255, 255, 255, 0.8) 100%
  );
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer {
  background: #0000000a;
  display: flex;
  padding: $spacer;

  a {
    margin-left: $spacer;
  }
}
